const el = jQuery('#rightcolumn'); // Element containing all comments
let ratings;
let votes = {};
const votes_settings = {
  api_base: '/wp-json/vote-comments/v1/',
  endpoints: {
    get_votes: {
      route: 'get-votes/',
      method: 'GET',
    },
    vote: {
      route: 'vote/',
      method: 'POST',
    },
  },
};

function doAjax(endpoint, data) {
  return jQuery.ajax({
    url: votes_settings.api_base + endpoint.route,
    method: endpoint.method,
    data,
  });
}

function voteComment(comment, updown) {
  doAjax(votes_settings.endpoints.vote, {
    vote: updown,
    id: comment,
  });
}

function getVotesFromServer(callback) {
  doAjax(votes_settings.endpoints.get_votes, {}).done(function (data) {
    votes = data;
    if (typeof callback === 'function') {
      callback.call();
    }
  });
}

function setVoteAmounts(vote) {
  // Find the comment element with comment ID
  ratings = el.find(`#comment - ${vote.ID} .comment_rating`);
  // Set vote amounts according to data
  ratings.find('.down').html(vote.downvotes);
  ratings.find('.up').html(vote.upvotes);
}

jQuery(document).ready(function () {
  getVotesFromServer(initAfterAjax);

  function initAfterAjax() {
    jQuery.each(votes, function (i, vote) {
      setVoteAmounts(vote);
    });
  }

  /*
   * Set up click handlers for voting
   * Prevent voting more a comment more than once via localStorage
   * Indicate past votes via adding a class
   */
  jQuery('.down, .up').each(function (e) {
    const commentId = jQuery(this).parents('article').attr('id').substring(8);
    const storagestring = `kansalaiskanavaVoted${commentId}`;
    let updown;
    let score;
    let vote;

    // Only accept vote if voting this comment hasn't been
    // registered to localStorage
    if (!localStorage.getItem(storagestring)) {
      jQuery(this).on('click', function () {
        updown = jQuery(this).attr('class');
        score = parseInt(jQuery(this).html());

        jQuery(this).html(score + 1);
        jQuery(this).addClass('voted');
        jQuery(this).off('click');
        jQuery(this).siblings().off('click');

        voteComment(commentId, updown);
        localStorage.setItem(storagestring, updown);
      });
      // Otherwise mark the previously chosen vote
    } else {
      vote = localStorage.getItem(storagestring);

      if (jQuery(this).attr('class') == vote) {
        jQuery(this).addClass('voted');
      }
    }
  });
});
